import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { strapiApi } from '../../services/strapi/service';

interface NewsInitialState {
  articleBreadcrumbName: string;
  newsPageTitle: string;
  newsPageMetaDescription: string;
}

const initialState: NewsInitialState = {
  articleBreadcrumbName: '',
  newsPageTitle: '',
  newsPageMetaDescription: '',
};

const newsSlice = createSlice({
  name: 'news',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(strapiApi.endpoints.fetchSingleItem.matchPending, state => {
        state.articleBreadcrumbName = '';
      })
      .addMatcher(strapiApi.endpoints.fetchSingleItem.matchFulfilled, (state, { payload }) => {
        state.articleBreadcrumbName = payload[0]?.attributes?.title;
        state.newsPageTitle = payload[0]?.attributes?.title;
        state.newsPageMetaDescription = payload[0]?.attributes?.seo.metaDescription;
      });
  },
});

export const selectNewsPageTitle = (state: RootState) => state.news.newsPageTitle;


export default newsSlice.reducer;
