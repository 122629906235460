import { useAppSelector } from 'hooks/redux';
import { selectFosCategories } from 'store/reducers/fosReducer';
import { whiteListApi } from 'services/whiteListService';

// TODO: переписать
export default function useController() {
  const categoryTypeSubscription = useAppSelector(selectFosCategories);
  const [subscribe, subscribeState] = whiteListApi.useSubscribeMutation();

  const sendData = async (data: Record<string, string>) => {
    await subscribe({
      name: data.name,
      phone: data.phone,
      email: data.email,
      categoryTypeSubscription,
    }).unwrap();
  };

  return {
    sendData,
    subscribeState
  };
}
