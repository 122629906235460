import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

interface Props {
  children: React.ReactNode;
}

export default function ScrollToTop({ children }: Props) {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'instant' as 'auto' });
  }, [location.pathname]);

  return <>{children}</>;
}
