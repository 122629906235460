import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { IPeriod } from 'types/fundProfitability';
import { localeCurrency } from 'helpers/currencyHelpers';
import { DATE_FORMAT } from 'const';
import { legalInfo } from 'router/Layout/data/footerData';
import { fundProfitabilityAPI } from 'services/fundProfitability';

export default function useController(ukText: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [legalInfoText, setLegalInfoText] = useState('');
  const [getFund] = fundProfitabilityAPI.useGetFundProfitabilityRegularMutation();

  const getFundsFromApi = async () => {
    const fundIds = legalInfo.map(item => item.id);
    return await getFund({ ids: fundIds }).unwrap();
  };

  const getLegalInfo = (funds: Record<number, IPeriod[]>) => {
    const localeProfitability = (profitability: number | null) => {
      return profitability ? `${localeCurrency(profitability)}%` : '-';
    };
    return legalInfo
      .map(item => {
        const periods = funds[item.id];
        return periods
          ? `${item.label}:
          1 мес. ${localeProfitability(periods[0].profitability)};
          3 мес. ${localeProfitability(periods[1].profitability)};
          6 мес.${localeProfitability(periods[2].profitability)};
          1 год ${localeProfitability(periods[3].profitability)};
          3 года ${localeProfitability(periods[4].profitability)};
          5 лет ${localeProfitability(periods[5].profitability)}.
          Доходность рассчитана на ${format(new Date(periods[1].endDate), DATE_FORMAT.FULL_DATE)}.
          <br/>
          <br/>
          `
          : '';
      })
      .join('');
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const funds = await getFundsFromApi();
      const legalInfo = getLegalInfo(funds);

      const text = ukText.replace('{legalInfo}', legalInfo);
      setLegalInfoText(text);
      setIsLoading(false);
    })();
  }, []);

  return {
    legalInfoText,
    isLoading,
  };
}
