import { IStrapiCommonRecordFields, IStrapiFileField } from 'types/strapi';

export type ICollectionResponse = any | object;

export interface ICollectionQuery {
  name: string;
  sortField?: string;
  sortDirection?: 'ASC' | 'DESC';
  pagination?: number;
  filterField?: string;
  filterType?: string;
  filter?: string | number | boolean;
  deepPopulate?: boolean;
}

export interface ISingleItemQuery {
  name: string;
  filterField: string;
  filterType: string;
  filter: string;
}

/** форматированный ответ fetchDocumentsInfo для отображения в компоненте */
export interface IDocumentsInfoResponse {
  /** Заголовок */
  title: string;
  listLinks?: {
    /** Название документа */
    text: string;
    /** Ссылка на документ */
    href: string;
  }[];
}

/** Запись "Информация о документе" */
export interface IDocumentInfoItem extends IStrapiCommonRecordFields {
  file: IStrapiFileField;
}

/** Доступные цвета карточек тарифов */
export enum AVAILABLE_TARIFF_CARDS_COLORS {
  PURPLE = 'purple',
  BLUE = 'blue',
  TEAL = 'teal',
  GREEN = 'green',
}

export interface IBrokerTariffAttributesModel extends IStrapiCommonRecordFields {
  /** Название тарифа */
  name: string;
  /** Текст информера */
  informerText?: string;
  /** Комиссия за сделки на фондовом рынке */
  stockMarketFee: string;
  /** Комиссия за сделки на валютном рынке */
  currencyMarketFee: string;
  /** Открытие и закрытие брокерского счета */
  openCloseAccount: string;
  /** Обслуживание брокерского счета */
  accountMaintenance: string;
  /** Пополнение и вывод денег с брокерского счета */
  depositWithdrawMoney: string;
  /** Дополнительный текст (отображается в левом верхнем углу) */
  additionalText?: string;
  /** Текст информера для обслуживания счета */
  accountMaintenanceInformer?: string;
  /** Текст карточки */
  color: AVAILABLE_TARIFF_CARDS_COLORS;
}
