import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useBreakpoint } from 'ii-ui-kit';
import { lockBodyScroll } from 'helpers/Scrollhelpers';

export default function useViewController() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const location = useLocation();
  const isLgUp = useBreakpoint(builder => builder.up('lg'));

  const onToggleHeader = () => {
    lockBodyScroll(!isOpen);
    setIsOpen(prev => !prev);
  };

  const onToggleAuthModal = () => {
    setIsOpenAuthModal(prev => !prev);
  };

  useEffect(() => {
    isOpen && onToggleHeader();
  }, [location]);

  return { isOpen, onToggleHeader, isLgUp, isOpenAuthModal, onToggleAuthModal };
}
