export const data = {
  defaultText: `
    <p>
      Настоящим физическое лицо, предоставляющее (вводящее) свои персональные данные и/или
      документы, содержащие персональные данные (в том числе документы и копии документов в
      электронной форме), на бумажных носителях или в электронном виде на сайтах и иных
      информационных ресурсах Общества с ограниченной ответственностью Брокерская компании
      «Ингосстрах – Инвестиции» в сети Интернет и/или с использованием иных электронных
      сервисов/мобильных приложений (при наличии) (далее – Информационные ресурсы в сети
      Интернет), в том числе для целей получения доступа к Сервисам удаленного обслуживания и
      использования их функционала, и/или в ходе телефонного разговора и электронной переписки с
      представителями Оператора, действуя своей волей и в своем интересе, предоставляет Обществу
      с ограниченной ответственностью Брокерская компания «Ингосстрах – Инвестиции» (основной
      государственный регистрационный номер: 1196746106194, место нахождения: 115035, г. Москва,
      вн. тер. г. муниципальный округ Замоскворечье, ул. Пятницкая, д. 12, стр. 2, этаж/помещ.
      1/I, ком. 9, далее – Оператор) согласие на обработку введенных (предоставленных)
      соответствующим образом персональных данных физического лица (далее – Клиент), а также
      согласие на получение рекламы товаров, работ и услуг Оператора и/или третьих лиц по сетям
      электросвязи (далее – Согласие).
    </p>
    <p>
      Настоящее согласие на обработку персональных данных (далее – Согласие) распространяется на
      следующие персональные данные Клиента:
    </p>
    <ul>
      <li>
        - все персональные данные Клиента, предоставленные на бумажных носителях, введенные
        и/или предоставленные на Информационных ресурсах в сети Интернет, в том числе при
        осуществлении перечисления денежных средств Оператору с использованием платежных систем,
        и/или в ходе телефонного разговора и электронной переписки с представителями Оператора;
      </li>
      <li>
        − персональные данные Клиента, полученные Оператором в результате идентификации Клиента
        в Единой системе идентификации и аутентификации (ЕСИА) или Системе межведомственного
        электронного взаимодействия (СМЭВ);
      </li>
      <li>
        − персональные данные, в том числе сообщенные дополнительно в связи с подготовкой к
        заключению/заключением с Оператором (в том числе при посредничестве агентов Оператора)
        договоров брокерского обслуживания и / или депозитарных договоров и/или договоров об
        инвестиционном консультировании (далее – Договоры), соглашения об электронном
        документообороте и использовании сервиса «Личный кабинет», иных электронных сервисов,
        используемых для установления правоотношений и взаимодействия с Клиентом, полученные при
        обновлении или обогащении содержания информационных систем Оператора, необходимые и
        достаточные для надлежащего исполнения Оператором Договоров и обязательств Оператора
        перед Клиентами;
      </li>
      <li>
        − иные персональные данные (далее – Персональные данные, персональная информация).
      </li>
    </ul>
    <p>
      Персональные данные могут обрабатываться Оператором в целях:
      заключения/исполнения/расторжения договоров/соглашений между Клиентом и Оператором;
      формирования рекламных предложений услуг Оператора, его партнеров и аффилированных лиц;
      продвижения услуг Оператора, его партнеров и аффилированных лиц на рынке путем осуществления
      прямых контактов с потенциальным потребителем с помощью средств связи; тестирования
      программного обеспечения; кредитного скоринга; определения размера убытков или ущерба; в
      целях проведения анализа и оценки страховых рисков; направления рассылок в целях повышения
      уровня клиентоориентированности и лояльности (включая проведение исследований (опросов) в
      области сервисных услуг и их качества, предоставляемых Оператором, его партнерами и
      аффилированными лицами), а также информации об условиях продления договоров с Оператором,
      его партнерами и аффилированными лицами; в целях продления договоров; в статистических
      целях; в целях обновления или дополнения Персональных данных любыми сведениями из любых
      источников; в целях совершенствования процесса оказания услуг Оператором, его партнерами и
      аффилированными лицами.
    </p>
    <p>
      Оператор вправе поручить обработку и/или передавать Персональные данные своим
      аффилированным лицам и иным лицам, в т.ч. следующим:
    </p>
    <ul>
      <li>
        - ООО «СК «Ингосстрах - Жизнь» (125171, г. Москва, Ленинградское ш., д. 16, стр. 9, ОГРН
        1037739872939),
      </li>
      <li>
        − ООО «СК «Ингосстрах-М» (117997, г. Москва, ул. Пятницкая, д. 12, стр. 2, ОГРН
        1027739362474),
      </li>
      <li>
        − АО УК «Ингосстрах-Инвестиции» (115035, г. Москва, ул. Пятницкая, д. 12, стр. 2, ОГРН
        1027700339666),
      </li>
      <li>
        − СПАО «Ингосстрах» (115035, г. Москва, ул. Пятницкая, д. 12, стр. 2, ОГРН 1027739362474),
      </li>
      <li>− АО «НПФ «Социум» (119017, г. Москва, Голиковский пер., д. 7, ОГРН 1147799013559),</li>
      <li>
        − ООО «Клиника ЛМС» (119146, г. Москва, Комсомольский пр-т, д. 28, эт. 1, 3 пом. III, VI,
        ОГРН 1057746061262),
      </li>
      <li>
        − БАНК СОЮЗ (АО) (127030, г. Москва, ул. Сущевская, д. 27, стр. 1, ОГРН 1027739447922),
      </li>
      <li>
        − АО "НПФ "ИНГОССТРАХ-ПЕНСИЯ" (119017, г. Москва, Голиковский пер., д. 7, ОГРН
        1157700018981),
      </li>
      <li>
        − АО «ФРЕЙТ ЛИНК» (123308, г. Москва, пр-т Маршала Жукова, д. 4, ОГРН 1027700447334),
      </li>
      <li>
        − АО «Интернет-Проекты» (197022, город Санкт-Петербург, улица Профессора Попова, дом 23
        литер д, помещение 28н, ОГРН 1027806085724),
      </li>
    </ul>
    <p>
      а также компаниям, оказывающим Оператору, его аффилированным лицам и иным лицам
      маркетинговые услуги (в т.ч. услуги рекламораспространителя), ИТ-услуги, иные услуги в целях
      формирования рекламных предложений, продвижения услуг путем осуществления прямых контактов с
      потенциальным потребителем с помощью средств связи; в статистических целях; определения
      размера убытков или ущерба; кредитного скоринга; в целях проведения анализа и оценки
      страховых рисков; направления рассылок в целях повышения уровня клиентоориентированности и
      лояльности (включая проведение исследований (опросов) в области сервисных услуг и их
      качества, а также информации об условиях продления договоров; в целях продления договоров; в
      целях обновления или дополнения Персональных данных любыми сведениями из любых источников; в
      целях совершенствования процесса оказания услуг; тестирования программного обеспечения.
      Передача Персональных данных может быть осуществлена в т.ч. посредством электронной почты,
      API или на материальных носителях (бумажных или электронных).
    </p>
    <p>
      Перечень Персональных данных, на обработку которых дается согласие: все персональные данные,
      предоставленные Клиентом в целях подготовки к заключению, заключения, исполнения,
      изменения, расторжения договоров (соглашений) между Клиентом и Оператором (в том числе:
      соглашения об электронном документообороте и использовании сервиса «Личный кабинет», иных
      электронных сервисов, используемых для установления правоотношений и взаимодействия с
      Клиентом, Договоров, иных соглашений), включая проведение мероприятий по идентификации
      физического лица и/или его представителя, а также в целях подготовки форм анкет, заявлений,
      уведомлений, не влекущих непосредственно заключение, расторжение, изменение
      соответствующего договора (соглашения), но необходимых для установления и поддержания
      правоотношений Оператора с Клиентом, все персональные данные, сообщенные дополнительно в
      связи с Договорами, а также персональные данные, полученные Оператором, его аффилированными
      лицами и партнёрами при обновлении или дополнении персональных данных (в том числе:
      фамилия, имя, отчество; дата и место рождения; гражданство; адрес (регистрации по месту
      жительства/пребывания и почтовый); номер телефона, адрес электронной почты и иные контактные
      данные; пол; семейное, социальное, имущественное положение (сведения об имуществе); сведения
      о наличии детей и иных лиц на иждивении; страна налогового резидентства; сведения об
      образовании, профессии, роде занятий, доходах, сбережениях и расходах; данные документа,
      удостоверяющего личность; данные документов, подтверждающих право иностранного гражданина
      или лица без гражданства на пребывание в Российской Федерации; данные миграционной карты,
      визы; идентификационный номер налогоплательщика; реквизиты банковского счета; данные
      договора банковского счета; сведения о банковских операциях; страховой номер индивидуального
      лицевого счета; файлы cookie, IP-адрес, Mac-адрес, Гео-позиция, push-токен и иные сведения).
    </p>
          <p>
      Вышеуказанное Согласие включает в себя также согласие на получение рекламы услуг Оператора,
      его аффилированных лиц и партнеров (включая указанных выше), в т.ч. по сетям электросвязи, в
      том числе посредством использования телефонной, факсимильной, подвижной радиотелефонной
      связи, в т.ч. направленной силами Оператора и иных указанных в Согласии лиц, получение
      рассылки рекламных сообщений по сети подвижной радиотелефонной связи от лиц, указанных в
      согласии. Согласие включает также согласие на трансграничную передачу Персональных данных.
    </p>
    <p>
      Клиент подтверждает, что уведомлен о том, что необходимая персональная информация будет
      передаваться третьим лицам, в том числе, но не ограничиваясь: депозитариям, регистраторам,
      специализированным депозитариям, банкам, налоговым органам, органам государственного
      контроля и надзора в случаях, установленных Договорами и применимыми нормами
      законодательства РФ.
    </p>
    <p>
      Перечень действий с Персональными данными, на совершение которых даются вышеуказанные
      согласия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
      изменение), извлечение, использование, передача, обезличивание, блокирование, удаление,
      уничтожение Персональных данных.
    </p>
    <p>
      Настоящим Клиент подтверждает, что уведомлен о том, что обработка Персональных данных будет
      осуществляться Оператором любым не противоречащим законодательству способом, как с
      использованием, так и без использования средств автоматизации, в том числе с передачей
      полученной информации по внутренней сети и сети «Интернет».
    </p>
    <p>
      Настоящим Клиент подтверждает, что обработка Персональных данных может осуществляться как
      Оператором, так и иными лицами, заключившими с Оператором соглашение на условиях
      конфиденциальности и ответственности за разглашение Персональных данных в рамках
      действующего законодательства Российской Федерации.
    </p>
    <p>
      Оператор обязуется обеспечивать сохранность и неразглашение Персональных данных в иных
      целях, нежели предусмотрены настоящим Согласием.
    </p>
    <p>
      Срок действия вышеуказанного Согласия составляет 15 (Пятнадцать) лет. Вышеуказанное Согласие
      может быть отозвано полностью или частично посредством передачи Оператору соответствующего
      заявления. Такое заявление может быть передано как на бумажном носителе за собственноручной
      подписью заявителя, так и посредством электронной почты (с указанием сведений, обязательных
      для включения в запрос на получение информации, касающейся обработки Персональных данных),
      посредством Личного кабинета (при наличии технической возможности) и иного электронного
      сервиса/мобильного приложения (если для взаимодействия с Оператором используется электронный
      сервис/мобильное приложение, при наличии последних и технической возможности).
    </p>
    <p>
      Настоящим Клиент подтверждает свою осведомленность о положениях Федерального закона от
      27.07.2006 № 152-ФЗ «О персональных данных», в том числе о порядке отзыва настоящего
      Согласия.
    </p>
    <p>Согласие может предоставляться одним из следующих способов:</p>
    <ul>
      <li>
        − путем его подписания собственноручной подписью на бумажном носителе и предоставления
        оригинала Оператору;
      </li>
      <li>
        − путем проставления отметки (галочки) в соответствующем поле и нажатия кнопки
        подтверждения ознакомления с текстом Согласия при пользовании Информационными ресурсами в
        сети Интернет;
      </li>
      <li>
        − посредством нажатия кнопки подтверждения ознакомления с текстом Согласия при пользовании
        Информационными ресурсами в сети Интернет.
      </li>
    </ul>
    <p>
      Настоящее Согласие признается мной и Оператором моим письменным согласием на обработку моих
      персональных данных и получение рекламы, данным согласно ст. 9 Федерального закона от
      27.07.2006 г. №152-ФЗ «О персональных данных» и ст.18 Федерального закона от 13.03.2006 г.
      №38-ФЗ «О рекламе».
    </p>
  `,
};
