import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFundItem, IFundPeriod, IPeriod } from 'types/fundProfitability';
import config from 'config';

export const fundProfitabilityAPI = createApi({
  reducerPath: 'fundProfitabilityAPI',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
  endpoints: build => ({
    getFundQuotesDynamic: build.mutation<
      IFundItem[],
      { id: number; startDate: string; endDate: string }
    >({
      query: (data: { id: number; startDate: string; endDate: string }) => ({
        url: `/api/Info/FundQuotesDynamic/${data.id}`,
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
    getFundProfitabilityRegular: build.mutation<
      Record<number, IPeriod[]>,
      { ids: number[] }
      >({
      query: (data: { ids: number[] }) => ({
        url: `/api/Info/FundProfitabilityRegular`,
        method: "POST",
        body: data.ids,
      }),
    }),
    getFund: build.mutation<IFundPeriod[], { id: number; startDate: string; endDate: string }>({
      query: (data: { id: number; startDate: string; endDate: string }) => ({
        url: `/api/Info/FundProfitability/${data.id}`,
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    }),
  }),
});
