import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from 'store/store';
import './styles/styles.scss';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
