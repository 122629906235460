import React  from 'react';
import { Modal, Button } from 'ii-ui-kit';

import BaseForm from 'components/BaseForm';
import BaseFormStatus from 'components/BaseFormStatus';

import useViewController from './useViewController';

import './FOSModal.scss';

// TODO: переписать
export default function FOSModal() {
  const { fields, shown, closableFosModal, setClosableFosModal, onSubmit, onCloseModal, subscribeState } =
    useViewController();
  const { isLoading, isSuccess, isError } = subscribeState

  return (
    <Modal
      className='fos-modal'
      shown={shown}
      closable={closableFosModal}
      onClose={onCloseModal}
      keepMounted={false}
      swipeable
    >
      <div className='fos-modal__content'>
        <BaseFormStatus
          isError={isError}
          isSuccess={isSuccess}
          successText={
            <>
              <div>Подписка оформлена!</div> <div>Ждите нас с хорошими новостями!</div>
            </>
          }
          appendSuccess={
            <Button
              className='fos-modal__success-btn'
              title='Отлично'
              size='m'
              color='secondary'
              onClick={onCloseModal}
            />
          }
          successClassName='fos-modal__success'
        />

        {!isSuccess && (
          <>
            <h3 className='fos-modal__title'>Подписка на уведомления</h3>
            <p className='fos-modal__subtitle'>
              Заполните форму, чтобы не пропустить выход нового продукта
            </p>
          </>
        )}

        {!isSuccess && (
          <BaseForm
            isLoading={isLoading}
            openTermsCB={v => setClosableFosModal(!v)}
            fields={fields}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </Modal>
  );
}
