import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';

export interface LeadRequest {
  email: string;
  name: string;
  phone: string;
}

export interface HelpRequest {
  email: string;
  firstName: string;
  mobilePhone: string;
  preferredCallTime: string;
  source: string;
  surname: string;
  utm: string;
  type?: string;
}

export const formApi = createApi({
  reducerPath: 'formApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
  endpoints: build => ({
    askQuestion: build.mutation<string, LeadRequest>({
      query: credentials => ({
        url: '/api/Magnet/MagnetLead',
        method: 'POST',
        body: {
          name: credentials.name,
          phone: credentials.phone,
          email: credentials.email,
          source: 'BMF',
          type: 'ask',
        },
      }),
    }),
    helpRequest: build.mutation<string, HelpRequest>({
      query: credentials => ({
        url: '/api/v1/ru/helprequest',
        method: 'POST',
        body: {
          email: credentials.email,
          firstName: credentials.firstName,
          mobilePhone: credentials.mobilePhone,
          preferredCallTime: credentials.preferredCallTime,
          surname: credentials.surname,
          source: credentials.source,
          utm: credentials.utm,
          type: credentials.type,
        },
      }),
    }),
  }),
});
