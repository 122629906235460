import {
  BASE_API_URL,
  REACT_APP_SITE_UK_URL,
  REACT_APP_URL_STRAPI
} from 'const';

const config = {
  apiUrl: BASE_API_URL,
  infoApiUrl: BASE_API_URL,
  socialLinks: {
    Telegram:
      'https://t.me/ingosinvest/?utm_campaign=telegram%7C%7Bcampaign_id%7D&utm_content=%7Bphrase_id%7D_%7Bretargeting_id%7D%7Ccid%7C%7Bcampaign_id%7D%7Cgid%7C%7Bgbid%7D%7Caid%7C%7Bad_id%7D%7Cadp%7C%7Baddphrases%7D%7Cpos%7C%7Bposition_type%7D%7Bposition%7D%7Csrc%7C%7Bsource_type%7D_%7Bsource%7D%7Cdvc%7C%7Bdevice_type%7D&utm_medium=cpc&utm_source=site&utm_term=%7Bkeyword%7D',
    Vkontakte:
      'https://vk.com/ingosinvest?utm_campaign=vk%7C%7Bcampaign_id%7D&utm_content=%7Bphrase_id%7D_%7Bretargeting_id%7D%7Ccid%7C%7Bcampaign_id%7D%7Cgid%7C%7Bgbid%7D%7Caid%7C%7Bad_id%7D%7Cadp%7C%7Baddphrases%7D%7Cpos%7C%7Bposition_type%7D%7Bposition%7D%7Csrc%7C%7Bsource_type%7D_%7Bsource%7D%7Cdvc%7C%7Bdevice_type%7D&utm_medium=cpc&utm_source=site&utm_term=%7Bkeyword%7D',
  },
  strapiApiUrl: `${REACT_APP_URL_STRAPI}/api/content`,
  strapiMediaUrl: `${REACT_APP_URL_STRAPI}/api`,
  apiUkUrl: REACT_APP_SITE_UK_URL
};

export default config;
