export function isIOS(): boolean {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/.test(navigator.userAgent);
}

export function isMacOS(): boolean {
  return navigator.platform.toLocaleLowerCase().indexOf("mac") > -1;
}
