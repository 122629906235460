import { Staging } from './types/app';

/** URL API сервера */
export const BASE_API_URL = process.env.REACT_APP_API_URL;

/** URL API Strapi */
export const REACT_APP_URL_STRAPI = process.env.REACT_APP_STRAPI_API_URL;

/** Урл онбординга ПИФ */
export const REACT_APP_ONBOARDING_PIF_URL = process.env.REACT_APP_ONBOARDING_PIF_URL || '';

/** Домен сайта УК */
export const REACT_APP_SITE_UK_URL = process.env.REACT_APP_SITE_UK_URL;

/** Урл сайта ук без протокола */
export const UK_SITE_URL_WITHOUT_PROTOCOL = 'www.ingosinvest-am.ru';

/** Урл сайта БК без протокола */
export const INGOBROKER_SITE = 'https://www.ingobroker.ru';

export enum DATE_FORMAT {
  YEAR = 'yyyy',
  DD_MM = 'dd.MM',
  MM_YY = 'MM.yy',
  MM_YEAR = 'MM.yyyy',
  FULL_DATE_FULL_MONTH = 'dd MMMM yyyy',
  FULL_DATE = 'dd.MM.yyyy',
  FORMAT_TO_NEW_DATE = 'MM-dd-yyyy',
  FULL_DATE_WITH_DASH_REVERSE = 'yyyy-MM-dd',
  FULL_DATE_WITH_DASH = 'dd-MM-yyyy',
  FULL_DATE_AND_FULL_TIME = 'dd.MM.yyyy HH:mm:ss',
  FULL_DATE_AND_TIME = 'dd.MM.yyyy HH:mm',
  FULL_DATE_AND_TIME_WITH_COMMA = 'dd.MM.yyyy, HH:mm',
  TIME_AND_FULL_DATE = 'HH:mm - dd.MM.yyyy',
}

/** Staging */
export const REACT_APP_STAGING: Staging = (process.env.REACT_APP_STAGING as Staging) ?? 'develop';

/** Роуты приложения */
export enum ROUTES {
  MAIN = '/',
  CONTACTS = '/contacts',
  INSIDERS = '/insiders',
  INVESTMENT_CONSULTING = '/investment-consulting',
  DISCLOSURE = '/disclosure',
  DISCLOSURE_TAB = '/disclosure/disclosure',
  DOCUMENTS_TAB = '/disclosure/documents',
  DISCLOSURE_TAB_PATTERN = '/disclosure/:url',
  DEPOSITARY = '/depositary',
  NEWS = '/news',
  NEWS_DETAIL = '/news/:url',
  MEDIA_DETAIL = '/news/media/:url',
  EVENTS_DETAIL = '/news/events/:url',
  BROKERAGE = '/brokerage',
  SPECIAL_DEPOSITORY_DOCUMENTS = '/special-depository-documents',
  OTHER_DOCUMENTS = '/other-documents',
  INFO_ABOUT_PROF_PARTICIPANT = '/info-about-prof-participant',
  INFO_ABOUT_STRUCTURE = '/info-about-structure',
  OWN_FUNDS_CALCULATION = '/own-funds-calculation',
  FINANCE_REPORTING = '/finance-reporting',
  LIST_OF_REGISTRARS_AND_DEPOSITORIES = '/list-of-registrars-and-depositories',
  SPECIALIZED_DEPOSITED_SERVICE = '/specialized-deposited-service',
  DOCUMENT_MANAGEMENT = '/document-management',
  ALL = '*',
}
