import { LinkIconI } from 'components/BaseHeader/types';

/** Ключи статичных текстов для футера из страпи */
export enum FOOTER_STATIC_TEXTS {
  UK = 'legalInfo',
  BK = 'legalInfoBk',
}

export interface DataFooter {
  footerContacts: {
    phone: string;
    workSchedule: {
      title: string;
      text: string;
    };
    links: LinkIconI[];
  };
  footerInfo: {
    title: string;
  };
  footerCopyright: {
    text: string;
    links: LinkIconI[];
  };
}
