import React, { MutableRefObject } from 'react';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { isIOS, isMacOS, isMobile } from 'helpers/browserUtils';
import 'simplebar/dist/simplebar.min.css';
import './CustomScrollBar.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

export default function CustomScrollBar({
  className,
  containerRef,
  style,
  children,
}: React.PropsWithChildren<Props>) {
  if (isIOS() || isMacOS() || isMobile()) {
    return (
      <div
        {...(containerRef ? { ref: containerRef } : {})}
        style={{ maxHeight: '100%', height: '100%', ...style }}
        className={classNames('custom-scroll-bar _default-scroll', className)}
      >
        {children}
      </div>
    );
  }
  return (
    <SimpleBar
      scrollableNodeProps={{ ref: containerRef }}
      style={{ maxHeight: '100%', height: '100%', ...style }}
      className={['custom-scroll-bar', className].join(' ')}
    >
      {children}
    </SimpleBar>
  );
}
