import BaseLoader from 'components/BaseLoader';
import FOSModal from 'components/modals/FOSModal';
import ScrollToTop from 'components/ScrollToTop';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'router/Layout';
import { AppRoutes, IRoute } from 'router/routes';

const SiteRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <Routes>
        {Object.values(AppRoutes).map(
          ({ component: Component, path, title, darkMode, footerInfo }: IRoute) => (
            <Route
              key={path}
              element={
                <Layout title={typeof title === 'function' ? title() : title} darkMode={darkMode} footerInfo={footerInfo} />
              }
            >
              <Route path={path} element={<Component />} />
            </Route>
          ),
        )}
      </Routes>
      <FOSModal />
    </Suspense>
  );
};

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <SiteRoutes />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
