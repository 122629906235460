import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Input,
  InputWithSwitch,
  Dropdown,
  Svg,
  Textarea,
  MenuItemModel,
} from 'ii-ui-kit';
import { debounce } from 'lodash-es';
import { customAlphabet } from 'nanoid';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import TermsAndConditions from 'components/modals/TermsAndConditions';
import { FieldInterface } from 'types/formTypes';

import './BaseForm.scss';

interface Props {
  fields: FieldInterface[];
  onSubmit: (inputs: Record<string, any>) => void;
  isLoading: boolean;
  darkMode?: boolean;
  title?: string;
  className?: string;
  btnText?: string;
  openTermsCB?: (value: boolean) => void;
  autoSubmit?: {
    debounceTime: number;
  };
}

export default function BaseForm({
  fields,
  onSubmit,
  darkMode,
  title,
  autoSubmit,
  className,
  isLoading,
  openTermsCB,
  btnText = 'Отправить',
}: Props) {
  const [showTerms, setShowTerms] = useState(false);
  const [switchValues, setSwitchValues] = useState<Record<string, boolean>>({});
  const selectValues = useRef<Record<string, MenuItemModel<string>>>({});

  const onShowTermsHandler = (value: boolean) => {
    setShowTerms(value);
    openTermsCB && openTermsCB(value);
  };
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Record<string, string>>({
    mode: 'onBlur',
  });

  const onSwitchClick = (name: string, value: boolean) => {
    setSwitchValues(prev => ({ ...prev, [name]: value }));
  };

  const onSelectValue = (name: string, value: MenuItemModel<string>) => {
    selectValues.current[name] = value;
  };

  const onSubmitForm = () => {
    const values = getValues();
    onSubmit({ ...values, ...selectValues.current });
  };

  const debounceTime = useMemo(() => autoSubmit?.debounceTime ?? 0, [autoSubmit]);

  const onDebounceSubmitForm = useCallback(debounce(onSubmitForm, debounceTime), [debounceTime]);

  useEffect(
    () => {
      if (!!autoSubmit) {
        onDebounceSubmitForm();
      }
    },
    autoSubmit ? [watch()] : [],
  );

  const nanoid = useMemo(() => customAlphabet('abcdefABCDEF', 5), []);
  const key = useRef(nanoid());
  const inputs = fields.map(field => {
    if (field.type === 'inputSwitch') {
      return (
        <InputWithSwitch
          key={field.name}
          inputProps={{
            id: field.name,
            placeholder: field.placeholder,
            label: field.label,
            addContainerClass: 'base-form__input',
            errorText: errors[field.name]?.message,
            validationStatus:
              errors[field.name] && !switchValues[field.switchName] ? 'error' : undefined,
            disabled: switchValues[field.switchName],
            form: 'formField',
            ...register(field.name, {
              required: !switchValues[field.switchName] ? field.required : undefined,
              pattern: field.pattern,
            }),
          }}
          switchProps={{
            id: field.switchName,
            label: field.switchText,
            value: switchValues[field.switchName],
            onChange: value => onSwitchClick(field.switchName, value),
          }}
        />
      );
    } else if (field.type === 'select') {
      return (
        <Dropdown
          key={field.name}
          className='base-form__input'
          value={{ value: '0', label: '' }}
          items={field.options}
          onSelect={v => {
            setValue(field.name, v.label);
            onSelectValue(field.name, v);
          }}
          control={
            <Input
              id={`${field.name}_${key.current}`}
              placeholder={field.placeholder}
              label={field.label}
              addContainerClass='base-form__select'
              errorText={errors[field.name]?.message}
              validationStatus={errors[field.name] ? 'error' : undefined}
              rightContentRender={() => (
                <div className='base-form__icon'>
                  <Svg name='chevron-down' />
                </div>
              )}
              form='formField'
              {...register(field.name, {
                required: field.required,
                pattern: field.pattern,
              })}
            />
          }
        />
      );
    } else if (field.type === 'textarea') {
      return (
        <Textarea
          value={undefined}
          key={field.name}
          id={`${field.name}_${key.current}`}
          label={field.label}
          placeholder={field.placeholder}
          addContainerClass='base-form__input'
          errorText={errors[field.name]?.message}
          validationStatus={errors[field.name] ? 'error' : undefined}
          form='formField'
          {...register(field.name, {
            required: field.required,
            pattern: field.pattern,
          })}
        />
      );
    } else {
      return (
        <Input
          key={field.name}
          id={`${field.name}_${key.current}`}
          placeholder={field.placeholder}
          label={field.label}
          addContainerClass='base-form__input'
          errorText={errors[field.name]?.message}
          mask={field.mask}
          validationStatus={errors[field.name] ? 'error' : undefined}
          form='formField'
          {...register(field.name, {
            required: field.required,
            pattern: field.pattern,
            minLength: field.minLength,
            maxLength: field.maxLength,
          })}
        />
      );
    }
  });

  return (
    <div className={classNames('base-form', className, { '__dark-mode': darkMode })}>
      <form className='base-form__form' onSubmit={handleSubmit(onSubmitForm)}>
        {title && <h3 className='base-form__title'>{title}</h3>}
        {inputs}
        {!autoSubmit && (
          <>
            <Button
              isLoading={isLoading}
              className='base-form__btn'
              title={btnText}
              size='l'
              color={darkMode ? 'primary-white' : undefined}
            />
            <div className='base-form__security'>
              Нажимая “{btnText}”, Вы соглашаетесь на&nbsp;
              <span
                onClick={() => onShowTermsHandler(true)}
                className={classNames('link', { '__dark-mode': darkMode })}
              >
                обработку персональных данных
              </span>
              &nbsp;и получение маркетинговых материалов
            </div>
          </>
        )}
      </form>
      <TermsAndConditions shown={showTerms} onClose={() => onShowTermsHandler(false)} />
    </div>
  );
}
