import { data as headerData } from 'router/Layout/data/headerData';
import mapPin from 'assets/icons/map-pin.svg';
import telegram from 'assets/icons/telegram.svg';
import vk from 'assets/icons/vk.svg';
import config from 'config';
import { ROUTES } from 'const';

export const data = {
  footerContacts: {
    phone: '8 (495) 787-70–60',
    workSchedule: headerData.headerContacts.workSchedule,
    links: [{ title: 'Офисы продаж', link: ROUTES.CONTACTS, icon: mapPin }],
  },
  footerInfo: {
    title: 'Правовая информация',
  },
  footerCopyright: {
    text: '© ООО БК «Ингосстрах-Инвестиции»',
    links: [
      {
        icon: telegram,
        link: config.socialLinks.Telegram,
      },
      {
        icon: vk,
        link: config.socialLinks.Vkontakte,
      },
    ],
  },
};

export const legalInfo = [
  {
    id: 639383,
    label: 'ОПИФ «Мой сейф»',
  },
  {
    id: 17586,
    label: 'ОПИФ «Мои облигации»',
  },
  {
    id: 17587,
    label: 'ОПИФ «Моя пенсия»',
  },
  {
    id: 17590,
    label: 'ОПИФ «Мое будущее»',
  },
  {
    id: 541872,
    label: 'ОПИФ «Мои акции»',
  },
  {
    id: 36081295,
    label: 'БПИФ «Ингосстрах - Корпоративные облигации»',
  },
  {
    id: 39270561,
    label: 'БПИФ «Ингосстрах Инфляционный»',
  },
  {
    id: 42215926,
    label: 'БПИФ «Ингосстрах Россия»',
  },
];
