import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { getPageNewsBreadcrumb, getPageNewsTitle } from 'helpers/breadcrumbHelpers';
import { ROUTES } from '../const';

const MainPage = lazy(() => import('pages/PageMain'));
const PageContacts = lazy(() => import('pages/PageContacts'));
const PageInsiders = lazy(() => import('pages/PageInsiders'));
const PageInvestmentConsulting = lazy(() => import('pages/PageInvestmentConsulting'));
const PageDisclosure = lazy(() => import('pages/PageDisclosure'));
const PageDepositary = lazy(() => import('pages/PageDepositary'));
const PageBrokerage = lazy(() => import('pages/PageBrokerage'));
const PageNews = lazy(() => import('pages/PageNews'));
const PageNewsDetail = lazy(() => import('pages/PageNewsDetail'));
const PageSpecialDepositoryDocuments = lazy(() => import('pages/PageSpecialDepositoryDocuments'));
const PageOtherDocuments = lazy(() => import('pages/PageOtherDocuments'));
const PageInfoProfParticipant = lazy(() => import('pages/PageInfoProfParticipant'));
const PageInfoAboutStructure = lazy(() => import('pages/PageInfoAboutStructure'));
const PageOwnFundsCalculation = lazy(() => import('pages/PageOwnFundsCalculation'));
const PageFinanceReporting = lazy(() => import('pages/PageFinanceReporting'));
const PageListOfRegistrarsAndDepositories = lazy(
  () => import('pages/PageListOfRegistrarsAndDepositories'),
);
const PageSpecializedDepositedService = lazy(() => import('pages/PageSpecializedDepositedService'));
const PageDocumentManagement = lazy(
  () => import('pages/PageDocumentManagement/page-document-management'),
);
const WrongWay = lazy(() => import('components/WrongWay'));

export const AppRoutes: Record<string, IRoute> = {
  Main: {
    path: ROUTES.MAIN,
    title: 'Главная',
    breadcrumb: 'Главная',
    component: MainPage,
  },
  Contacts: {
    path: ROUTES.CONTACTS,
    title: 'Контакты',
    component: PageContacts,
  },
  PageInsiders: {
    path: ROUTES.INSIDERS,
    title: 'Инсайдерам',
    breadcrumb: 'Инсайдерам',
    component: PageInsiders,
  },
  PageDisclosure: {
    path: ROUTES.DISCLOSURE_TAB_PATTERN,
    title: 'Раскрытие информации',
    breadcrumb: 'Раскрытие информации',
    component: PageDisclosure,
  },
  PageInvestmentConsulting: {
    path: ROUTES.INVESTMENT_CONSULTING,
    title: 'Инвестиционное консультирование',
    breadcrumb: 'Инвестиционное консультирование',
    component: PageInvestmentConsulting,
  },
  PageDepositary: {
    path: ROUTES.DEPOSITARY,
    title: 'Депозитарное обслуживание',
    breadcrumb: 'Депозитарное обслуживание',
    component: PageDepositary,
  },
  PageNews: {
    path: ROUTES.NEWS,
    title: 'Новости',
    breadcrumb: 'Новости',
    component: PageNews,
  },
  PageNewsDetails: {
    path: ROUTES.NEWS_DETAIL,
    title: getPageNewsTitle,
    breadcrumb: getPageNewsBreadcrumb,
    component: PageNewsDetail,
  },
  PageMediaDetails: {
    path: ROUTES.MEDIA_DETAIL,
    title: getPageNewsTitle,
    component: PageNewsDetail,
  },
  PageEventsDetails: {
    path: ROUTES.EVENTS_DETAIL,
    title: getPageNewsTitle,
    component: PageNewsDetail,
  },
  PageBrokerage: {
    path: ROUTES.BROKERAGE,
    title: 'Брокерское обслуживание',
    breadcrumb: 'Брокерское обслуживание',
    component: PageBrokerage,
  },
  PageSpecialDepositoryDocuments: {
    path: ROUTES.SPECIAL_DEPOSITORY_DOCUMENTS,
    title: 'Документы спецдепозитария',
    breadcrumb: 'Документы спецдепозитария',
    component: PageSpecialDepositoryDocuments,
  },
  PageDocumentManagement: {
    path: ROUTES.DOCUMENT_MANAGEMENT,
    title: 'Электронный документооборот',
    breadcrumb: 'Электронный документооборот',
    component: PageDocumentManagement,
  },
  PageOtherDocuments: {
    path: ROUTES.OTHER_DOCUMENTS,
    title: 'Прочие документы',
    breadcrumb: 'Прочие документы',
    component: PageOtherDocuments,
  },
  PageInfoProfParticipant: {
    path: ROUTES.INFO_ABOUT_PROF_PARTICIPANT,
    title: 'Информация о профессиональном участнике рынка ценных бумаг',
    breadcrumb: 'Информация о профессиональном участнике рынка ценных бумаг',
    component: PageInfoProfParticipant,
  },
  PageInfoAboutStructure: {
    path: ROUTES.INFO_ABOUT_STRUCTURE,
    title: 'Информация о структуре и составе участников, конечных владельцах',
    breadcrumb: 'Информация о структуре и составе участников, конечных владельцах',
    component: PageInfoAboutStructure,
  },
  PageOwnFundsCalculation: {
    path: ROUTES.OWN_FUNDS_CALCULATION,
    title: 'Расчет собственных средств',
    breadcrumb: 'Расчет собственных средств',
    component: PageOwnFundsCalculation,
  },
  PageFinanceReporting: {
    path: ROUTES.FINANCE_REPORTING,
    title: 'Бухгалтерская (финансовая) отчётность',
    breadcrumb: 'Бухгалтерская (финансовая) отчётность',
    component: PageFinanceReporting,
  },
  PageListOfRegistrarsAndDepositories: {
    path: ROUTES.LIST_OF_REGISTRARS_AND_DEPOSITORIES,
    title: 'Перечень регистраторов и депозитариев',
    breadcrumb:
      'Перечень регистраторов и депозитариев, в том числе иностранных, в которых депозитарию открыты лицевые счета (счета депо) номинального держателя',
    component: PageListOfRegistrarsAndDepositories,
  },
  PageSpecializedDepositedService: {
    path: ROUTES.SPECIALIZED_DEPOSITED_SERVICE,
    title: 'Специализированное депозитарное обслуживание',
    breadcrumb: 'Специализированное депозитарное обслуживание',
    component: PageSpecializedDepositedService,
  },
  Error: {
    path: ROUTES.ALL,
    title: 'Ошибка',
    component: WrongWay,
  },
};

export interface IFooterAddInfo {
  footerTextPrefix?: string;
  showUKLegalInfo?: boolean;
}

export interface IRoute {
  path: ROUTES;
  title: string | (() => string);
  component: LazyExoticComponent<ComponentType<any>>;
  breadcrumb?: BreadcrumbComponentType<string> | string;
  darkMode?: boolean;
  footerInfo?: IFooterAddInfo;
}
