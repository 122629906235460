import React from 'react';
import BaseLegalInfo from '../BaseLegalInfo';
import useController from './useController';

type UKLegalInfoProps = {
  ukText: string;
};

export default function UKLegalInfo({ ukText }: UKLegalInfoProps) {
  const { legalInfoText } = useController(ukText);

  return (
    <BaseLegalInfo title='Правовая информация УК'>
      <div>
        <div dangerouslySetInnerHTML={{ __html: legalInfoText }} />
      </div>
    </BaseLegalInfo>
  );
}
