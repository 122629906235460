import React from 'react';
import CustomScrollBar from 'components/CustomScrollBar';
import './BaseLegalInfo.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function BaseLegalInfo({ title, children }: Props) {
  return (
    <div className='base-legal-info'>
      <h4 className='base-legal-info__title'>{title}</h4>
      <CustomScrollBar className='base-legal-info__text' style={{ maxHeight: 210 }}>
        {children}
      </CustomScrollBar>
    </div>
  );
}
