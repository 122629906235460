import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Container from 'components/Container';
import BaseInform from 'components/BaseInform';
import { DataFooter } from 'components/BaseFooter/types';
import { IFooterAddInfo } from 'router/routes';
import BaseLegalInfo from './components/BaseLegalInfo';
import UKLegalInfo from './components/UKLegalInfo';
import { isExternalUrl } from 'helpers/UrlHelpers';

import './BaseFooter.scss';
import { useFooterStaticText } from './hooks/use-static-text';
import BaseLoader from 'components/BaseLoader/BaseLoader';

interface Props extends DataFooter {
  additionalInfo?: IFooterAddInfo;
}

export default function BaseFooter({
  footerContacts,
  footerInfo,
  footerCopyright,
  additionalInfo,
}: Props) {
  const { ukText, bkText, isLoading } = useFooterStaticText();

  return (
    <div className='base-footer'>
      <Container>
        <div className='base-footer__container'>
          <div className='base-footer__content-top'>
            <a className='base-footer__contacts-phone' href={`tel:${footerContacts.phone}`}>
              {footerContacts.phone}
            </a>
            <div className='base-footer__contacts'>
              <div className='base-footer__contacts-schedule'>
                <BaseInform
                  informerPosition='left'
                  align='start'
                  informerClassName='base-footer__contacts-schedule-informer'
                  informer={
                    <div dangerouslySetInnerHTML={{ __html: footerContacts.workSchedule.text }} />
                  }
                >
                  {footerContacts.workSchedule.title}
                </BaseInform>
              </div>
              {footerContacts.links.map(({ link, title, icon }, i) =>
                isExternalUrl(link) ? (
                  <a key={i} href={link} className='base-footer__contacts-link'>
                    <span className='base-footer__contacts-link-icon'>
                      <img src={icon} />
                    </span>
                    {title}
                  </a>
                ) : (
                  <Link key={i} to={link} className='base-footer__contacts-link'>
                    <span className='base-footer__contacts-link-icon'>
                      <img src={icon} />
                    </span>
                    {title}
                  </Link>
                ),
              )}
            </div>
          </div>
          <div
            className={classNames('base-footer__info', {
              '_additional-legal-info': additionalInfo?.showUKLegalInfo,
            })}
          >
            {additionalInfo?.showUKLegalInfo && !isLoading && <UKLegalInfo ukText={ukText} />}
            {isLoading ? (
              <BaseLoader />
            ) : (
              <BaseLegalInfo
                title={
                  additionalInfo?.showUKLegalInfo ? 'Правовая информация БК' : footerInfo.title
                }
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: additionalInfo?.footerTextPrefix
                      ? additionalInfo?.footerTextPrefix + bkText
                      : bkText,
                  }}
                />
              </BaseLegalInfo>
            )}
          </div>
          <div className='base-footer__copyright'>
            <div className='base-footer__copyright-text'>{footerCopyright.text}</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
