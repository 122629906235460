import { useAppSelector } from 'hooks/redux';
import { selectNewsPageTitle } from '../store/reducers/newsReducer';

export const getPageNewsBreadcrumb = () => {
  return <span>{useAppSelector(selectNewsPageTitle)}</span>
};

export const getPageNewsTitle = () => {
  return useAppSelector(selectNewsPageTitle);
};
