import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getPhoneAsWhiteList } from 'helpers/regexRules';
import config from 'config';

interface SubscribeWhiteListResponse {
  name: string;
  phone: string;
  email: string;
  categoryTypeSubscription: number[];
  surName?: string;
}

export const whiteListApi = createApi({
  reducerPath: 'whiteListApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
  endpoints: build => ({
    subscribe: build.mutation<string, SubscribeWhiteListResponse>({
      query: credentials => ({
        url: '/api/waitList/subscribe',
        method: 'POST',
        body: {
          ...credentials,
          phone: getPhoneAsWhiteList(credentials.phone),
        },
      }),
    }),
  }),
});
