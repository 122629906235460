import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import BaseInform from 'components/BaseInform';
import LoginModal from 'components/modals/LoginModal';

import CandyboxButton from './components/CandyboxButton';
import HeaderContent from './components/HeaderContent';
import HeaderLinks from './components/HeaderLinks';

import { LOGO_SRC, LOGO_SRC_WHITE } from 'helpers/UrlHelpers';
import { DataHeader } from 'components/BaseHeader/types';
import useViewController from './useViewController';

import './BaseHeader.scss';

type Props = DataHeader;

export default function BaseHeader({
  headerLinks,
  headerContent,
  headerContacts,
  darkMode,
}: Props) {
  const { isOpen, onToggleHeader, isLgUp, isOpenAuthModal, onToggleAuthModal } =
    useViewController();

  const logoImg = darkMode && !isOpen ? LOGO_SRC_WHITE : LOGO_SRC;

  return (
    <header className='base-header'>
      <Container>
        <div className='base-header__container'>
          <Link to='/' className='base-header__logo'>
            <img src={logoImg} alt='logo' />
          </Link>

          {isLgUp && (
            <div className='base-header__links'>
              {!isOpen && <HeaderLinks darkMode={darkMode && !isOpen} links={headerLinks} />}
            </div>
          )}

          <div className='base-header__buttons'>
            <CandyboxButton
              isOpen={isOpen}
              onClick={onToggleHeader}
              darkMode={darkMode && !isOpen}
            />
          </div>
        </div>
      </Container>

      {isOpen && (
        <HeaderContent className='base-header__content' headerContent={headerContent}>
          <>
            <div className='base-header__contacts'>
              {headerContacts.phone && (
                <a className='base-header__contacts-phone' href={`tel:${headerContacts.phone}`}>
                  {headerContacts.phone}
                </a>
              )}
              {headerContacts.email && (
                <a className='base-header__contacts-email' href={`mailto:${headerContacts.email}`}>
                  {headerContacts.email}
                </a>
              )}
              <div className='base-header__contacts-schedule'>
                <BaseInform
                  informerPosition='right'
                  align='start'
                  informer={
                    <div dangerouslySetInnerHTML={{ __html: headerContacts.workSchedule.text }} />
                  }
                  informerClassName='base-header__contacts-schedule-informer'
                >
                  {headerContacts.workSchedule.title}
                </BaseInform>
              </div>
            </div>
          </>
        </HeaderContent>
      )}
      <LoginModal show={isOpenAuthModal} onClose={onToggleAuthModal} />
    </header>
  );
}
