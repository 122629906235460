import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import {
  ICollectionResponse,
  ICollectionQuery,
  ISingleItemQuery,
  IDocumentsInfoResponse,
  IDocumentInfoItem,
  IBrokerTariffAttributesModel,
} from './types';
import { INGOBROKER_SITE } from 'const';
import { IStrapiFetchListResponse } from 'types/strapi';

export const strapiApi = createApi({
  reducerPath: 'strapiApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.strapiApiUrl }),
  endpoints: build => ({
    fetchCollection: build.query<ICollectionResponse[], ICollectionQuery>({
      query: ({
        name,
        sortField: sort,
        sortDirection: sortAsc,
        pagination,
        filterField,
        filterType,
        filter,
        deepPopulate,
      }) => ({
        url: `${name}?${deepPopulate ? 'populate=deep,4' : 'populate=*'}${
          sort ? `&sort=${sort}:${sortAsc ? sortAsc : 'DESC'}` : ''
        }&pagination[pageSize]=${pagination}${
          filterField ? `&filters[${filterField}][${filterType}]=${filter}` : ''
        }`,
      }),
      transformResponse: (res: { data: any }) => res.data,
    }),
    fetchSingleItem: build.query<ICollectionResponse[], ISingleItemQuery>({
      query: ({ name, filterField, filterType, filter }) => ({
        url: `${name}?filters[${filterField}][${filterType}]=${filter}&populate=*`,
      }),
      transformResponse: (res: { data: any }) => res.data,
    }),
    /** Запрашивает данные документов из Strapi */
    fetchDocumentsInfo: build.query<IDocumentsInfoResponse, ICollectionQuery>({
      query: ({ name, sortField, sortDirection }) => ({
        url: `${name}?populate=*&sort=${sortField}:${sortDirection}`,
      }),
      transformResponse: (res: IStrapiFetchListResponse<IDocumentInfoItem>) => {
        return {
          title: 'Информационные документы',
          listLinks: res.data.map(el => {
            return {
              text: el.attributes.file.data.attributes.alternativeText,
              href: `${INGOBROKER_SITE}/api${el.attributes.file.data.attributes.url}`,
            };
          }),
        };
      },
    }),
    /** Получить доступные тарифы БК */
    getBrokerTariffs: build.query<
      IStrapiFetchListResponse<IBrokerTariffAttributesModel>,
      ICollectionQuery
    >({
      query: ({ name, sortField, sortDirection, pagination }) => ({
        url: `${name}?populate=*&sort=${sortField}:${sortDirection}&pagination[pageSize]=${pagination}`,
      }),
    }),
  }),
});

export const {
  useFetchCollectionQuery,
  useFetchSingleItemQuery,
  useFetchDocumentsInfoQuery,
  useGetBrokerTariffsQuery,
} = strapiApi;
