import './MainButton.scss';

import classNames from 'classnames';
import { Button, ButtonProps } from 'ii-ui-kit';
import React from 'react';

interface IMainButtonProps extends Omit<ButtonProps, 'color'> {
  color: 'gradient' | 'secondary-gradient';
}

const MainButton: React.FC<IMainButtonProps> = ({ color, className, ...props }) => {
  return (
    <Button
      className={classNames('main-button', `main-button_${color}`, className)}
      color='primary'
      {...props}
    />
  );
};

export default MainButton;
