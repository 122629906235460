import React from "react";

import classNames from "classnames";
import { NavLink } from "react-router-dom";
import type { BreadcrumbData, BreadcrumbsRoute, Options } from "use-react-router-breadcrumbs";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import "./index.scss";

interface IBreadcrumbsProps {
  routes?: BreadcrumbsRoute<string>[] | undefined;
  options?: Options | undefined;
  className?: string;
}

export const Breadcrumbs = React.memo(function ({ routes, options, className }: IBreadcrumbsProps) {
  const breadcrumbs: BreadcrumbData[] = useBreadcrumbs(routes, options);
  if (breadcrumbs.length < 2) {
    return null;
  }
  return (
    <div className={classNames("breadcrumbs", className)}>
      {breadcrumbs.map(({ breadcrumb, key }, index, arr) => (
          <NavLink
            className={classNames("breadcrumbs__link", {
              "breadcrumbs__link--active": arr.length - 1 === index,
            })}
            key={key}
            to={key}
          >
            {index > 0 && <span className="breadcrumbs__dot" />}
            {breadcrumb}
          </NavLink>
        ))}
    </div>
  );
});
