import './Container.scss';

import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  wrapperClassName?: string;
}

const Container: React.FC<Props> = ({ className, wrapperClassName, children }): JSX.Element => {
  return (
    <div className={classNames('common-container', className)}>
      <div className={classNames('common-wrapper', wrapperClassName)}>{children}</div>
    </div>
  );
};

export default Container;
