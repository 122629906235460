import React from 'react';
import { Modal } from 'ii-ui-kit';
import './TermsAndConditions.scss';
import { data } from './data/data';

interface Props {
  shown: boolean;
  onClose: () => void;
  text?: string;
}

export default function TermsAndConditions({ shown, onClose, text }: Props) {
  return (
    <Modal shown={shown} onClose={onClose}>
      <div className='terms-and-conditions__content'>
        <h3>Согласие на обработку персональных данных.</h3>
        <div className='terms-and-conditions__text' dangerouslySetInnerHTML={{__html: text ?? data.defaultText}}></div>
      </div>
    </Modal>
  );
}
