import { strapiApi } from 'services/strapi';
import { FOOTER_STATIC_TEXTS } from '../types';

export const useFooterStaticText = () => {
  const { data, isLoading, isFetching } = strapiApi.useFetchCollectionQuery({
    name: 'static-texts',
    pagination: 1000,
  });

  const ukText = data?.find(item => item.attributes.techName === FOOTER_STATIC_TEXTS.UK).attributes
    .text as string;
  const bkText = data?.find(item => item.attributes.techName === FOOTER_STATIC_TEXTS.BK).attributes
    .text as string;

  return {
    ukText,
    bkText,
    isLoading: isLoading || isFetching,
  };
};
