export const localeCurrency = (currency: number, options?: { withSign: boolean }): string => {
  const negativeSign = currency < 0;
  const localeCurrency = Math.abs(currency).toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(options?.withSign && !negativeSign
      ? {
          signDisplay: 'always',
        }
      : {}),
  });

  return `${negativeSign ? '-' : ''}${localeCurrency}`;
};
