import { DataHeader } from 'components/BaseHeader/types';
import { AppRoutes } from 'router/routes';
import { ROUTES } from 'const';

export const data: DataHeader = {
  headerLinks: [
    {
      link: ROUTES.DISCLOSURE_TAB,
      title: 'Раскрытие информации',
    },
    {
      link: ROUTES.DOCUMENTS_TAB,
      title: 'Документы',
    },
    {
      link: AppRoutes.PageInsiders.path,
      title: 'Инсайдерам',
    },
    {
      link: AppRoutes.Contacts.path,
      title: 'Контакты',
    },
  ],
  headerContent: {
    links: [],
    additionalCategories: [
      {
        title: 'Раскрытие информации',
        link: ROUTES.DISCLOSURE_TAB,
        links: [
          {
            title: 'Информация о профессиональном участнике рынка ценных бумаг',
            link: ROUTES.INFO_ABOUT_PROF_PARTICIPANT,
            isRedirect: false,
          },
          {
            title: 'Структура и состав участников',
            link: ROUTES.INFO_ABOUT_STRUCTURE,
            isRedirect: false,
          },
          {
            title: 'Расчёт собственных средств',
            link: ROUTES.OWN_FUNDS_CALCULATION,
            isRedirect: false,
          },
          {
            title: 'Бухгалтерская (финансовая) отчётность',
            link: ROUTES.FINANCE_REPORTING,
            isRedirect: false,
          },
        ],
      },
      {
        title: 'Документы',
        link: ROUTES.DOCUMENTS_TAB,
        links: [
          {
            title: 'Брокерское обслуживание',
            link: ROUTES.BROKERAGE,
            isRedirect: false,
          },
          {
            title: 'Инвестиционное консультирование',
            link: ROUTES.INVESTMENT_CONSULTING,
            isRedirect: false,
          },
          {
            title: 'Депозитарное обслуживание',
            link: ROUTES.DEPOSITARY,
            isRedirect: false,
          },
          {
            title: 'Документы спецдепозитария',
            link: '/special-depository-documents',
            isRedirect: false,
          },
          {
            title: 'Прочие документы',
            link: ROUTES.OTHER_DOCUMENTS,
            isRedirect: false,
          },
        ],
      },
      {
        title: "Инсайдерам",
        link: AppRoutes.PageInsiders.path,
        links: [],
      },
      {
        title: "Контакты",
        link: AppRoutes.Contacts.path,
        links: [],
      },
    ]
  },
  headerContacts: {
    workSchedule: {
      title: 'График работы',
      text: '<div>' +
        '<div>Пн-Пт: с 09-00 до 18-00</div>' +
        '<div>Сб-Вс: выходной</div>' +
        '</div>',
    },
  },
};
