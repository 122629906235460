import { createSlice } from '@reduxjs/toolkit';
import { formApi } from 'services/formService';
import { RootState } from 'store/store';
import { RequestTypeFormStatus } from 'types/formTypes';

interface FormInitialState {
  status: RequestTypeFormStatus;
}

const initialState: FormInitialState = {
  status: 'pending',
};

const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(formApi.endpoints.askQuestion.matchRejected, state => {
        state.status = 'error';
      })
      .addMatcher(formApi.endpoints.askQuestion.matchFulfilled, state => {
        state.status = 'success';
      })
      .addMatcher(formApi.endpoints.helpRequest.matchRejected, state => {
        state.status = 'error';
      })
      .addMatcher(formApi.endpoints.helpRequest.matchFulfilled, state => {
        state.status = 'success';
      });
  },
});

export const selectFormStatus = (state: RootState) => state.form.status;

export default formSlice.reducer;
