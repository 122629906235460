import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { stringify } from "qs";

import config from 'config';
import { IStrapiFetchListResponse } from "types/strapi";

import { IEmitentNewsItem, INewsItem } from "./types";


export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.strapiApiUrl }),
  endpoints: build => ({
    /** Получить список новостей эмитентов */
    getEmitentNews: build.query<IStrapiFetchListResponse<IEmitentNewsItem>, { page: number; pageSize: number; sort: string; }>({
      query: ({ page, pageSize, sort }) => {
        const paramsQuery = stringify({
          "pagination[page]": page,
          "pagination[pageSize]": pageSize,
          sort: sort,
          populate: "*"
        });
        return {
          url: `/bk-novosti-emitentovs?${paramsQuery}`,
        }
      },
    }),

    /** Получить новости */
    getNews: build.query<IStrapiFetchListResponse<INewsItem>, { page: number; pageSize: number; sort: string; }>({
      query: ({ page, pageSize, sort }) => {
        const paramsQuery = stringify({
          "pagination[page]": page,
          "pagination[pageSize]": pageSize,
          sort: sort,
          populate: "*"
        });
        return {
          url: `/bk-novostis?${paramsQuery}`,
        }
      },
    }),
  }),
});

