import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { LinkI } from 'components/BaseHeader/types';

import './HeaderLinks.scss';
import { useAppDispatch } from 'hooks/redux';
import { fosSlice } from 'store/reducers/fosReducer';
import { FOSCategoryTypeSubscriptions } from 'types/fosTypes';
interface Props {
  links: LinkI[];
  className?: string;
  darkMode?: boolean;
}

export default function HeaderLinks({ links, className, darkMode }: Props) {
  const dispatch = useAppDispatch();
  const { showModal } = fosSlice.actions;

  const onShowFosModal = (category?: FOSCategoryTypeSubscriptions) => {
    dispatch(showModal([category ?? FOSCategoryTypeSubscriptions.TRADING_INSTRUMENTS]));
  };
  return (
    <div className={classNames('header-links', className)}>
      {links.map(({ link, title, isRedirect, inProgress, fosCategoryType }, i) => {
        const LinkTag = isRedirect ? 'a' : Link;
        return (
          <LinkTag
            key={i}
            to={link}
            onClick={inProgress ? () => onShowFosModal(fosCategoryType) : undefined}
            className={classNames('header-links__link', {
              '__dark-mode': darkMode,
            })}
            {...(isRedirect ? { href: link, target: '_blank' } : {})}
          >
            {title}
          </LinkTag>
        );
      })}
    </div>
  );
}
