import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface FOSInitialState {
  categoryTypeSubscription: number[];
  shownModal: boolean;
}

const initialState: FOSInitialState = {
  categoryTypeSubscription: [],
  shownModal: false,
};

export const fosSlice = createSlice({
  name: 'fosReducer',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<number[]>) => {
      state.categoryTypeSubscription = action.payload;
      state.shownModal = true;
    },
    closeModal: state => {
      state.shownModal = false;
    },
  },
});

export const selectFosCategories = (state: RootState) => state.fosModal.categoryTypeSubscription;
export const selectShowFOSModal = (state: RootState) => state.fosModal.shownModal;

export default fosSlice.reducer;
