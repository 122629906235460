/** Регулярка для email (латинский алфавит, знак собаки, точка) */
export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#.$%&'*+-/=?^_'{|}~]+@[a-zA-Z]+((-(?=[a-zA-Z]))|[a-zA-Z0-9])*\.[a-zA-Z]{2,}$/;

/** Регулярка для phone (в формате:  +7 или 8 999 999 99 99, первые три цифры в скобках или без, между остальными пробел или тире*/
export const PHONE_REGEX =
  /^(\+7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

/** Регулярка для имени или фамилии только русские буквы дефис и пробел, дефис и пробел допустимы только внутри слова*/
export const NAME_REGEX = /^\s*[а-яА-ЯёЁ]+(([-\s](?=[а-яА-ЯёЁ]))|[а-яА-ЯёЁ])*\s*$/;

export const getPhoneAsWhiteList = (phone: string) => {
  return phone.replace(/[+ ()-]/g, '');
};
