import './Layout.scss';

import BaseFooter from 'components/BaseFooter';
import BaseHeader from 'components/BaseHeader';
import Container from 'components/Container';
import { Breadcrumbs } from 'components/breadcrumbs';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { AppRoutes, IFooterAddInfo } from 'router/routes';

import { data as footerData } from './data/footerData';
import { data as headerData } from './data/headerData';

interface Props {
  title?: string;
  darkMode?: boolean;
  footerInfo?: IFooterAddInfo;
}

export default function Layout({ title, footerInfo, darkMode }: Props) {
  return (
    <Fragment>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <BaseHeader darkMode={darkMode} {...headerData} />
      <main className='layout-main'>
        <Container>
          <Breadcrumbs
            routes={Object.values(AppRoutes)}
            className='layout-breadcrumbs'
            options={{ disableDefaults: true }}
          />
        </Container>
        <Outlet />
      </main>
      <BaseFooter {...footerData} additionalInfo={footerInfo} />
    </Fragment>
  );
}
