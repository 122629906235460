import { formApi } from 'services/formService';
import { strapiApi } from 'services/strapi/service';
import { whiteListApi } from 'services/whiteListService';
import { newsApi } from 'services/news';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import formReducer from './reducers/formReducer';
import fosReducer from './reducers/fosReducer';
import { fundProfitabilityAPI } from 'services/fundProfitability';
import newsReducer from './reducers/newsReducer';

const rootReducer = combineReducers({
  [formApi.reducerPath]: formApi.reducer,
  [whiteListApi.reducerPath]: whiteListApi.reducer,
  [strapiApi.reducerPath]: strapiApi.reducer,
  [fundProfitabilityAPI.reducerPath]: fundProfitabilityAPI.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  form: formReducer,
  fosModal: fosReducer,
  news: newsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(formApi.middleware)
      .concat(whiteListApi.middleware)
      .concat(strapiApi.middleware)
      .concat(fundProfitabilityAPI.middleware)
      .concat(newsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
