import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Informer, Svg } from 'ii-ui-kit';

import './BaseInform.scss';

interface Props {
  informer?: React.ReactNode;
  informerTitle?: string;
  informerText?: string;
  informerPosition?: 'left' | 'right';
  align?: 'start' | 'center';
  className?: string;
  contentClassName?: string;
  containerClassName?: string;
  informerClassName?: string;
}

export default function BaseInform({
  align = 'center',
  informer,
  informerText,
  informerTitle,
  informerPosition = 'left',
  className,
  contentClassName,
  containerClassName,
  children,
  informerClassName,
}: PropsWithChildren<Props>) {
  return (
    <div
      style={{ alignItems: align }}
      className={classNames('base-inform', `_${informerPosition}`, className)}
    >
      <div className={classNames('base-inform__container', containerClassName)}>
        <div className='base-inform__icon'>
          <Svg name='info' />
        </div>
        <div className={classNames('base-inform__content', contentClassName)}>{children}</div>
      </div>

      <div className={classNames('base-inform__informer', informerClassName)}>
        <Informer title={informerTitle} text={informerText} isOpen>
          {informer}
        </Informer>
      </div>
    </div>
  );
}
