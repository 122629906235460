import React, { useState } from 'react';
import { Button } from 'ii-ui-kit';
import classNames from 'classnames';
import { ReactComponent as ArrowBottom } from 'assets/common/arrow-bottom.svg';
import './AdditionalCategory.scss';
import { CategoryI } from '../BaseHeader/types';

interface Props {
  className?: string;
  headlineClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  category: CategoryI;
}

export default function AdditionalCategory({
  className,
  category,
  headlineClassName,
  contentClassName,
  itemClassName,
}: Props) {
  return (
    <div className={classNames('additional-header-category', className)}>
      <div
        className={classNames(
          'additional-header-category__headline',
          headlineClassName,
        )}
      >
        <a className='additional-header-category__title' href={category.link}>{category.title}</a>
      </div>
      <div
        className={classNames(
          'additional-header-category__content',
          contentClassName,
        )}>
        {category.links.map(({ link, title, isRedirect }, i) => (
            <a
              key={i}
              href={link}
              target={isRedirect ? '_blank' : ''}
              className={classNames(
                'main-header-category__link header-small-link',
                itemClassName,
              )}>
              {title}
            </a>
          ))}
      </div>
    </div>
  );
}
