import React from 'react';
import classNames from 'classnames';

import { ReactComponent as DangerIcon } from 'assets/icons/danger-icon.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/like-icon.svg';

import './BaseFormStatus.scss';

interface Props {
  isSuccess: boolean;
  isError: boolean;
  firstName?: string;
  className?: string;
  successText?: JSX.Element;
  appendSuccess?: JSX.Element;
  successClassName?: string;
}

// TODO: переписать
export default function BaseFormStatus({
  firstName,
  className,
  successText,
  appendSuccess,
  successClassName,
  isSuccess,
  isError,
}: Props) {
  if (!isSuccess && !isError) return null;

  return (
    <div className={classNames('base-form-status', className)}>
      {isError && (
        <div className='base-form-status__error d-center'>
          <DangerIcon />
          <h4 className='base-form-status__error-title d-center'>Упс, что-то пошло не так</h4>
          <p className='base-form-status__error-text'>
            Пожалуйста, введите данные повторно и нажмите «Отправить»
          </p>
        </div>
      )}
      {isSuccess && (
        <div className={classNames('base-form-status__success', successClassName)}>
          <LikeIcon className='base-form-status__success-icon' />
          <h4 className='base-form-status__success-name'>
            Спасибо{firstName ? `, ${firstName}` : ''}!
          </h4>
          <div className='base-form-status__success-text'>
            {successText ?? 'Мы свяжемся с Вами в ближайшее время'}
          </div>
          {appendSuccess}
        </div>
      )}
    </div>
  );
}
