import React from 'react';
import { Modal } from 'ii-ui-kit';
import MainButton from 'components/MainButton';
import { REACT_APP_ONBOARDING_PIF_URL } from "const";

import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import './LoginModal.scss';

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function LoginModal({ show, onClose }: Props) {
  return (
    <Modal className='login-modal' onClose={onClose} shown={show}>
      <SunIcon className='login-modal__icon' />
      <h3 className='login-modal__title'>
        Станьте клиентом
        <br />
        УК Ингосстрах-Инвестиции
      </h3>

      <div className='login-modal__buttons'>
        <a className='no-link' href='https://lk.ingosinvest-am.ru/auth' target='_blank'>
          <MainButton
            className='login-modal__btn'
            color='secondary-gradient'
            size='l'
            title='Войти'
          />
        </a>

        <a
          className='no-link'
          href={REACT_APP_ONBOARDING_PIF_URL}
          target='_blank'
        >
          <MainButton
            className='login-modal__btn'
            color='gradient'
            size='l'
            title='Стать клиентом'
          />
        </a>
      </div>
    </Modal>
  );
}
