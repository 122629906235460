import React from 'react';
import { Button } from 'ii-ui-kit';
import classNames from 'classnames';

import { ReactComponent as CandyboxOpen } from 'assets/icons/candybox-open.svg';
import { ReactComponent as CandyboxClosed } from 'assets/icons/candybox-closed.svg';

import './CandyboxButton.scss';

interface Props {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
  darkMode?: boolean;
}

export default function CandyboxButton({ isOpen, onClick, darkMode, className }: Props) {
  return (
    <Button
      size='xs'
      color='ghost'
      className={classNames('candybox-button', className, {
        '__dark-mode': darkMode,
      })}
      onClick={onClick}
      icon={
        isOpen ? (
          <>
            <CandyboxOpen className='candybox-button__icon-open' />
            <CandyboxOpen className='candybox-button__icon-open-hover' />
          </>
        ) : (
          <CandyboxClosed className='candybox-button__icon-closed' />
        )
      }
    />
  );
}
