import './BaseLoader.scss';

import { Loader } from 'ii-ui-kit';
import React from 'react';

export default function BaseLoader() {
  return (
    <div className='base-loader'>
      <Loader />
    </div>
  );
}
