import { useState } from 'react';
import { FieldInterface } from 'types/formTypes';
import { EMAIL_REGEX, PHONE_REGEX, NAME_REGEX } from 'helpers/regexRules';
import { fosSlice, selectShowFOSModal } from 'store/reducers/fosReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import useController from './useController';

// TODO: переписать
export default function useViewController() {
  const fields: FieldInterface[] = [
    {
      type: 'text',
      name: 'name',
      label: 'Имя',
      placeholder: 'Имя',
      required: 'Укажите имя',
      pattern: {
        value: NAME_REGEX,
        message: 'Укажите имя',
      },
    },
    {
      type: 'tel',
      name: 'phone',
      label: 'Мобильный телефон',
      placeholder: 'Мобильный телефон',
      required: 'Укажите мобильный номер',
      mask: '+7 (999) 999-99-99',
      pattern: {
        value: PHONE_REGEX,
        message: 'Проверьте мобильный номер',
      },
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-mail',
      placeholder: 'E-mail',
      required: 'Укажите адрес электронной почты',
      pattern: {
        value: EMAIL_REGEX,
        message: 'Проверьте адрес электронной почты',
      },
    },
  ];

  const { sendData, subscribeState } = useController();

  const { closeModal } = fosSlice.actions;
  const shown = useAppSelector(selectShowFOSModal);
  const dispatch = useAppDispatch();

  const [closableFosModal, setClosableFosModal] = useState(true);

  const onSubmit = async (data: Record<string, string>) => await sendData(data);

  const onCloseModal = () => {
    dispatch(closeModal());
  };

  return {
    fields,
    shown,
    closableFosModal,
    setClosableFosModal,
    onSubmit,
    onCloseModal,
    subscribeState
  };
}
