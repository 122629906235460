import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderContentI } from 'components/BaseHeader/types';
import Container from 'components/Container';
import AdditionalCategory from 'components/AdditionalCategory';
import { isExternalUrl } from 'helpers/UrlHelpers';

import './HeaderContent.scss';

interface Props {
  className?: string;
  headerContent: HeaderContentI;
  children?: JSX.Element;
}

export default function HeaderContent({
  className,
  headerContent,
  children,
}: Props) {

  return (
    <div className={classNames('header-content', className)}>
      <div className='header-content__inner'>
        <Container>
          <div className='header-content__content'>
            <div className='header-content__additional-categories'>
              {headerContent.additionalCategories.map((category, i) => (
                <AdditionalCategory
                  category={category}
                  key={i}
                  className='header-content__additional-category'
                />
              ))}
            </div>
          </div>
          <div className='header-content__slot'>{children}</div>
        </Container>
      </div>
    </div>
  );
}
